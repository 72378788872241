export const Current_Month_Layer_Title = [
	{title: "프레스 산업군 안전사고\n 사례", key:"safety_accident_cases"},
	{title: "프레스 설치,사용 및 \n정비작업 점검", key:"inspection_of_press"},
	{title: "프레스 산업군 법령시행", key:"laws_on_Press"},
	{title: "중대재해 알아보기", key:"learn_major_disaster"},
	{title: "시의적이슈 온열질환", key:"heat_illness"},

	{title: "매월 이슈 콘텐츠", key:"issue_content"},
	{title: "외국인근로자 재해예방표지\n 2인1조", key:"foreign_group"},
	{title: "그림으로 이해하는 안전보건", key:"safety_picture"},
	{title: "사례로 알아보는 안전한 \n현장만들기", key:"safe_place_example"},
	{title: "안전교육 지게차", key:"safe_fork_lift"},

	{title: "프레스산업군 \n산업안전보건법 35조", key:"article_thirty_five"},
	{title: "중대재해처벌법 홍보 \n콘텐츠 (Q&A)", key:"punishment_qna"},
	{title: "시의적이슈 코로나19 \n재확산방지", key:"corona_19"},
	{title: "밀폐공간작업 안전수칙", key:"confined_space"},
	{title: "외국인근로자 재해예방표지 \n폭발성물질", key:"foreign_explosive"},

	{title: "그림으로 이해하는 포스터 \n(끼임)", key:"stuck_picture"},
	{title: "감전재해 예방 3대 \n기본 수칙", key:"preventing_electric_shock"},
	{title: "당신이 중대재해에 대해 \n모르는 5가지", key:"disaster_five_things"},
	{title: "안전교육 사다리", key:"safe_ladder"},
	{title: "프레스 산업군 안전사고", key:"press_safety_accident"},

	{title: "안전인증이란?", key:"safety_certification"},
	{title: "중대재해처벌법 홍보", key:"punishment_promotion"},
	{title: "프레스 작업 안전수칙 퀴즈", key:"work_safety_quiz"},
	{title: "외국인근로자 재해예방표지 \n추락위험", key:"foreign_falling"},
]
